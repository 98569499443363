/* 
// 09. Counter
*/

.counter-box {
    .counter-icon {
        background: rgba($white, 0.02);
    height: 90px;
    width: 90px;
    border-radius: 50%;
    line-height: 90px;
    text-align: center;
    font-size: 46px;
    box-shadow: 0 3px 0px rgba($white, 0.20);
    }
}