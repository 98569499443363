/* 
// variables.scss
*/

@font-face {
    font-family: 'GeosansLight';
    src: url('../fonts/GeosansLight.ttf');
}
@font-face {
    font-family: 'GeosansLightOblique';
    src: url('../fonts/GeosansLight-Oblique.ttf');
}

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');

// Color variables
$white:                     #ffffff;
$black:                     #000000;


$primary:                   #a21b33;
$secondary:                 #6c757d;
$success:                   #15d68c;
$info:                      #59dbff;
$info2:                     #c51b3a ;
$warning:                   #c10c2d;
$danger:                    #ee4a4a;
$dark:                      #343a40;
$muted:                     #9da9bb;
$light:                     #f7f9fe;
$body-bg:                   #ffffff;



$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "info2": $info2,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "body-bg": $body-bg,
    "black": $black,
    "white": $white
);

// Font
$font-primary:            'GeosansLight', sans-serif;
$font-primary1:           'Rubik', sans-serif;
$font-secondary:          'GeosansLight', sans-serif;
$font-oblique:          'GeosansLightOblique', sans-serif;